<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container :title="$route.name" :exportInfo="currItems" :departments="currDepartments">
      <template v-slot:bottom-header>
        <v-row class="mx-4">
          <v-col cols="12" md="3">
            <v-autocomplete v-model="filters.departments" :items="catalogues.departments" item-text="nombre" item-value="iddepartamento" label="Departamentos" prepend-icon="mdi-sitemap"
            multiple persistent-hint hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" md="1">
            <Basic-Btn text=" Buscar" color="success" icon="mdi-magnify" @click="refresh()" :loading="loading" />
          </v-col>
          <v-col cols="12" md="2">
            <Basic-Btn text="Reporte Agrupado" color="primary" icon="mdi-file-download" @click="downloadReport('grouped', {})" :loading="loading" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="itemsPerPage" item-key="no_empleado" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="open(item, 'new')">mdi-plus-circle</v-icon></template
            ><span>Agregar registro de vacaciones</span></v-tooltip
          >
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="showRecords(item)">mdi-eye</v-icon></template
            ><span>Mostrar registros de vacaciones</span></v-tooltip
          >
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="downloadReport('individual', item)">mdi-file-download</v-icon></template
            ><span>Reporte Individual de Vacaciones</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.new" title="Nuevo registro de vacaciones">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="save()" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.new.nombre" label="Empleado" prepend-icon="mdi-account-group" disabled dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select v-model="forms.new.tipo" :items="catalogues.types" item-text="text" item-value="value" label="Tipo" :rules="rules.tipo"
            prepend-icon="mdi-plus-minus-variant" dense outlined></v-select>
          </v-col>
          <template v-if="forms.new.tipo === 'ALTA'">
            <v-col cols="6">
              <Date-Picker v-model="forms.new.fecha_inicio" label="Fecha inicio" offLimit/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="forms.new.dias" label="Días" prepend-icon="mdi-calendar" :rules="rules.dias" dense outlined required></v-text-field>
            </v-col>
          </template>
          <template v-else-if="forms.new.tipo === 'BAJA'">
            <v-col cols="12">
              <v-select v-model="forms.new.tipo_dias" :items="catalogues.typesSelectDates" label="Dias solicitados" prepend-icon="mdi-calendar" no-data-text="No se encontraron resultados" dense outlined required></v-select>
            </v-col>
            <template v-if="forms.new.tipo_dias === 'Días consecutivos'">
              <v-col cols="6">
                <Date-Picker v-model="forms.new.fecha_inicio" label="Fecha inicio" offLimit/>
              </v-col>
              <v-col cols="6">
                <Date-Picker v-model="forms.new.fecha_fin" label="Fecha fin" offLimit/>
              </v-col>
            </template>
            <template v-else-if="forms.new.tipo_dias === 'Fechas específicas'">
              <v-col cols="12">
                <v-date-picker v-model="forms.new.fechas" locale="es" multiple no-title full-width></v-date-picker>
              </v-col>
            </template>
            <v-col cols="12">
              <v-select v-model="forms.new.medio_dia" :items="catalogues.middleDay" label="Tiempo solicitado" prepend-icon="mdi-calendar" no-data-text="No se encontraron resultados" dense outlined required></v-select>
            </v-col>
          </template>
          <v-col cols="12">
            <v-textarea v-model="forms.new.comentario" label="Comentario" prepend-icon="mdi-comment-text-outline" rows="3" :rules="rules.comentario" dense outlined required></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.record" title="Registros de vacaciones" maxWidth="1200px">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
      </template>
      <v-data-table :headers="forms.record.headers" :items="forms.record.items" :loading="loading"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, -1] }"
      :items-per-page="10" item-key="no_empleado" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.id)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Dialog-Form>
    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar el registro de vacaciones?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
      </template>
    </Delete-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services"
import { Loader, DialogForm, DeleteMessage, BasicAlert, TableContainer, BasicBtn, DatePicker } from "@/components"
import downloadjs from 'downloadjs'
import moment from 'moment-timezone'

export default {
  name: "vacation",
  components: {
    Loader,
    DialogForm,
    DeleteMessage,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    catalogues: {
      users: [],
      departments: [],
      types: [
        { text: 'ALTA (Agrega saldo de vacaciones)', value: 'ALTA' },
        { text: 'BAJA (Elimina saldo de vacaciones)', value: 'BAJA' },
      ],
      typesSelectDates: ["Días consecutivos", "Fechas específicas"],
      middleDay: [
        { text: 'Medio día', value: true },
        { text: 'Día completo', value: false },
      ]
    },
    headers: [ 
      { text: "ID", align: "left", sortable: true, value: "idusuario" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "Apellido paterno", align: "left", sortable: true, value: "apellido_paterno" },
      { text: "Apellido materno", align: "left", sortable: true, value: "apellido_materno" },
      { text: "", align: " d-none", sortable: true, value: "nombre_completo" },
      { text: "Puesto", align: "left", sortable: true, value: "puesto" },
      { text: "Departamento", align: "left", sortable: true, value: "departamento" },
      { text: "Total de días correspondientes", align: "left", sortable: true, value: "dias_periodo_actual" },
      { text: "Días tomados", align: "left", sortable: true, value: "dias_tomados" },
      { text: "Días pendientes por disfrutar", align: "left", sortable: true, value: "saldo" },
      { text: "Años de antigüedad", align: "left", sortable: true, value: "antiguedad" },
      { text: "Último día de vacaciones", align: "left", sortable: true, value: "ultima_fecha" },
      { text: "Acciones", align: "left", sortable: true, value: "actions" },
    ],
    items: [],
    currItems: [],
    currDepartments: [],
    itemsPerPage: 20,
    deleted: null,
    filters: {
      users: [],
      departments: []
    },
    forms: {
      new: {
        idusuario: '',
        nombre: '',
        tipo: '',
        tipo_dias: '',
        fecha_inicio: '',
        fecha_fin: '',
        dias: '',
        fechas: [],
        antiguedad: '',
        comentario: ''
      },
      record: {
        headers: [
          { text: "ID", align: "left", sortable: true, value: "id" },
          { text: "Antigûedad (Años)", align: "left", sortable: true, value: "antiguedad" },
          { text: "Movimiento", align: "left", sortable: true, value: "movimiento" },
          { text: "Fecha inicio", align: "left", sortable: true, value: "fecha_inicio" },
          { text: "Fecha fin", align: "left", sortable: true, value: "fecha_fin" },
          { text: "Días", align: "left", sortable: true, value: "dias" },
          { text: "Saldo final", align: "left", sortable: true, value: "saldo_final" },
          { text: "Comentario", align: "left", sortable: true, value: "comentario" },
          { text: "Acciones", align: "left", sortable: true, value: "actions" },
        ],
        items: []
      }
    },
    rules: {
      fecha_inicio: [(v) => !!v || "El campo es requerido"],
      fecha_fin: [(v) => !!v || "El campo es requerido"],
      dias: [(v) => (/^\d{1,3}(\.5)?$/.test(v) && parseFloat(v) >= 0.5) || "El campo es inválido"],
      tipo: [(v) => !!v || "El campo es requerido"],
      comentario: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 500) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      new: false,
      record: false,
      remove: false
    }
  }),
  watch: {
    search(val) {
      this.itemsPerPage = (val) ? -1 : 20
    }
  },
  mounted() {
    // this.getUsers()
    this.getDepartments()
    this.refresh()
  },
  methods: {
    getUsers() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.catalogues.users = response.data.data;
          for (let i = 0; i < this.catalogues.users.length; i++) {
            if (this.catalogues.users[i].fecha_borrado) {
              this.catalogues.users.splice(i, 1)
              i--
            }
          }
          for (let i = 0; i < this.catalogues.users.length; i++) {
            this.catalogues.users[i].nombreCompleto =
              (this.catalogues.users[i].detalles_usuarios[0].no_empleado ? this.catalogues.users[i].detalles_usuarios[0].no_empleado : "") +
              (this.catalogues.users[i].detalles_usuarios[0].nombre ? " " + this.catalogues.users[i].detalles_usuarios[0].nombre : "") +
              (this.catalogues.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.catalogues.users[i].detalles_usuarios[0].apellido_paterno : "") +
              (this.catalogues.users[i].detalles_usuarios[0].apellido_materno ? " " + this.catalogues.users[i].detalles_usuarios[0].apellido_materno : "")
          }
        })
        .catch((error) => {
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = error.response.data.message || "Servicio no disponible"
        })
        .finally(() => {
          this.loading = false
        });
    },
    getDepartments() {
      this.loading = true;
      this.axios
        .get(services.routes.department)
        .then((response) => {
          this.catalogues.departments = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          if (key === 'fechas') {
            this.forms.new[key] = [];
          }
          else {
            this.forms.new[key] = "";
          }
        }
        this.dialogs.new = false;
      }
      else if (this.dialogs.remove) {
        this.deleted = null
        this.dialogs.remove = false;
      }
      else if (this.dialogs.record) {
        this.forms.record.items = [];
        this.dialogs.record = false;
      }
    },
    refresh() {
      this.loading = true
      let params = JSON.parse(JSON.stringify(this.filters))
      this.axios
        .get(services.routes.vacations, { params })
        .then((response) => {
          this.items = response.data.data
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].nombre_completo = this.items[i].nombre + ' ' + this.items[i].apellido_paterno + ' ' + this.items[i].apellido_materno
          }
          this.currDepartments = []
          for (let i = 0; i < this.catalogues.departments.length; i++) {
            if (this.filters.departments.indexOf(this.catalogues.departments[i].iddepartamento) >= 0) {
              this.currDepartments.push(this.catalogues.departments[i].nombre)
            }
          }
        })
        .catch((error) => {
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = error.response.data.message || "Servicio no disponible"
        })
        .finally(() => {
          this.loading = false
        });
    },
    open(data, form) {
      this.forms.new.idusuario = data.idusuario
      this.forms.new.nombre = data.nombre_completo
      this.forms.new.antiguedad = data.antiguedad
      this.dialogs[form] = true
    },
    save() {
      if (this.$refs.formNew.validate()) {

        let check = true

        if (this.forms.new.tipo == 'ALTA' && (!this.forms.new.fecha_inicio || !this.forms.new.dias)) {
          check = false
        }
        else if (this.forms.new.tipo == 'BAJA') {
          if (this.forms.new.medio_dia !== true && this.forms.new.medio_dia !== false) {
            check = false
          }
          if (this.forms.new.tipo_dias === 'Días consecutivos' && (!this.forms.new.fecha_inicio || !this.forms.new.fecha_fin)) {
            check = false
          }
          else if (this.forms.new.tipo_dias === 'Fechas específicas' && !this.forms.new.fechas.length) {
            check = false
          }
          else if (this.forms.new.tipo_dias === '') {
            check = false
          }
        }

        if (!check) {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = "La información es incorrecta";
          return false
        }

        let data = JSON.parse(JSON.stringify(this.forms.new));

        data.fechas.sort(function(a, b){
          const d1 = new Date(b).getTime();
          const d2 = new Date(a).getTime();
          return (d1 < d2) ? 1 : -1; 
        });

        if (data.tipo === 'ALTA') {
          const endDate = moment(this.forms.new.fecha_inicio).add(parseInt(this.forms.new.dias) - 1, 'days').format('YYYY-MM-DD')
          data.fecha_fin = endDate
          data.tipo_dias = ''
          data.fechas = []
        }
        else if (data.tipo === 'BAJA') {
          if (data.tipo_dias === 'Días consecutivos') {
            data.fechas = []
            const diff = moment(this.forms.new.fecha_fin).diff(this.forms.new.fecha_inicio, 'days')
            data.dias = diff + 1
          }
          else if (data.tipo_dias === 'Fechas específicas') {
            data.fecha_inicio = data.fechas[0]
            data.fecha_fin = data.fechas[data.fechas.length - 1]
            data.dias = data.fechas.length
          }
        }
        
        this.loading = true;
        this.axios
          .post(services.routes.vacations, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Registro de vacaciones creado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    downloadReport(type, data) {
      this.loading = true
      if (type === 'grouped') {
        data.departamentos = this.filters.departments
      }
      this.axios({
        url: services.routes.reports + '/vacation/' + type,
        method: 'POST',
        data: { data },
        responseType: 'blob'
      })
      .then((response) => {
        const filename = (type === 'individual') ? 'Reporte Individual de Vacaciones' : 'Reporte Agrupado de Vacaciones'
        downloadjs(response.data, filename + '.pdf', "application/pdf");
      })
      .catch((error) => {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "El reporte no puede ser generado";
      })
      .finally(() => {
        this.loading = false;
      });
    },
    getFiltered(values) {
      this.currItems = values
    },
    showRecords(data) {
      this.loading = true
      this.axios
        .post(services.routes.vacations + '/detail', { data })
        .then((response) => {
          this.forms.record.items = response.data.data
          this.dialogs.record = true
        })
        .catch((error) => {
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = error.response.data.message || "Servicio no disponible"
        })
        .finally(() => {
          this.loading = false
        });
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.vacations, { data: { data: { idvacacion: this.deleted } } })
          .then((response) => {
            this.dialogs.remove = false
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Registro de vacaciones eliminado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    }
  },
};
</script>

<style scoped>
</style>
